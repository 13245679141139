@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/underline";

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: $spacing-fortyeight $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
    clear: both;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    padding: $spacing-eighty $spacing-fiftysix;
  }
}

.article {
  width: 100%;
  margin-bottom: 1rem;
  clear: both;

  @include breakpoint-medium-up {
    width: 50%;
    padding: 0 1rem;
    margin-bottom: 1.4rem;
  }

  @include breakpoint-larger-up {
    width: calc(100% / 3);
    padding: 0 1.3rem;
    margin-bottom: 1.2rem;
  }
}

.title {
  @include responsive-title-4;

  display: flex;
  flex-direction: column;
  gap: $spacing-eight;
  margin-bottom: $spacing-sixteen;
  width: 100%;

  @include breakpoint-medium-up {
    margin-bottom: $spacing-thirtytwo;
    gap: $spacing-sixteen;
  }

  &::before {
    @include underline;

    content: "";
    display: block;
  }
}

.link {
  display: block;
  width: 100%;
  color: $color-text-primary;

  &:hover,
  &:active {
    color: $color-text-primary;
  }
}

.figure {
  position: relative;
  margin: 0 0 1.5rem;
  display: block;
  overflow: hidden;
  border-radius: 1rem;
}

.image {
  width: 100%;
  max-width: 100%;
  display: block;
}

.heading {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
  margin-bottom: 1rem;

  @include breakpoint-medium-up {
    font-size: 2.2rem;
  }
}

.subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related-articles-container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacing-twentyfour;
    width: 100%;

    & > article {
      margin-bottom: $spacing-eight;
    }
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;

    & > article {
      margin-bottom: $spacing-thirtytwo;
    }
  }
}
