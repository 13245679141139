@import "styles/_theme-wellobe.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/images";
@import "~@styles/utils/variables/spacing";
@import "~@styles/utils/mixins/typography";
@import "~@styles/utils/mixins/underline";

.underline {
  @include underline;
}

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;
  padding: $spacing-fortyeight $spacing-sixteen;

  @include breakpoint-medium-up {
    padding: $spacing-sixtyfour $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    padding: $spacing-eighty $spacing-fiftysix;
  }

  @include breakpoint-larger-up {
    max-width: $desktop-max-width;
    margin: 0 auto;
    padding: $spacing-eighty 0;
  }
}

.featureWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @include breakpoint-large-up {
    align-items: flex-start;
  }
}

.articlesTeasersWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-forty;

  @include breakpoint-medium-up {
    flex-direction: row;
    gap: $spacing-twentyfour;
  }

  @include breakpoint-large-up {
    gap: $spacing-thirtytwo;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-eight;

  @include breakpoint-medium-up {
    gap: $spacing-sixteen;
  }

  @include breakpoint-large-up {
    gap: $spacing-twentyfour;
    width: 67%;
    margin: auto 0;
  }
}

.title {
  @include responsive-title-4;

  margin: 0;
}

.description {
  @include text-16-regular;

  @include breakpoint-large-up {
    @include text-20-regular;
  }
}

.see-all-button-container {
  display: flex;
  justify-content: center;
  width: 100%;

  @include breakpoint-medium-up {
    margin-top: $spacing-eight;
  }
}

.see-all-button {
  @include text-16-semi-bold;

  padding: calc($spacing-twelve - 0.1rem) calc($spacing-twentyfour - 0.1rem);
  margin: 0;
}
